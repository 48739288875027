import { SEO } from '@components/common'
import config from '@config/seo_meta.json'
import processLocale, { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'

interface seoProps {
  title: string
  description: string
  image?: any
}
const SeoHead = ({ title, description, image }: seoProps) => {
  const { locale } = useRouter()
  const router = useRouter()
  const currentLocale = processLocale(locale)
  let url = getConfigByLocale(currentLocale, 'openGraphUrlWithLocale')
  if(!url) url = config.openGraph.url
 
  if(router.pathname !== '/') {
    url = `${url}${router.asPath}`
  }

  return (
    <SEO
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title: title,
        description: description,
        url,
        images: [
          {
            url:
              image?.url! ||
              config.openGraph.images[0].url,
            width: '800',
            height: '600',
            alt: title,
          },
        ],
      }}
    />
 
  )
}

export default SeoHead
