import getProductsByCollection from '@commerce/api/operations/get-products-by-collection'
import { Layout, SeoHead } from '@components/common'
import getGlobalStaticProps from '@lib/globalStaticProps'
import useCustomer from '@lib/hooks/useCustomer'
import { SliceZone } from '@prismicio/react'
import { components } from '@slices/index'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { createClient, findCollectionHandles, getDocByUid } from 'prismicio'

export async function getStaticProps({
  locale,
  previewData,
}: GetStaticPropsContext) {
  const client = createClient({ previewData })
  const globalProps = await getGlobalStaticProps(client, locale)
  const translations = await serverSideTranslations(locale ?? 'en-au', [
    'common',
  ])

  let collectionProducts: any = []

  const doc = await getDocByUid('general', 'homepage', client, locale)
  const collectionHandles = findCollectionHandles(doc?.data?.slices)

  if (collectionHandles.length > 0) {
    for (let i = 0; i < collectionHandles.length; i++) {
      collectionProducts[i] = await getProductsByCollection(
        collectionHandles[i],
        locale,
        'no-cache'
      )
    }
  }

  return {
    props: {
      ...globalProps,
      ...translations,
      doc,
      collectionProducts,
    },
    //revalidate: 14400, // Refresh every 4 hours
  }
}

export default function Home({
  doc,
  collectionProducts,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { customer } = useCustomer()
  const customerTags =
    customer?.tags?.map((tag: string) => tag.toLowerCase()) || []
  const { data } = doc || {}
  const { seoDescription, seoImage, seoTitle } = data || {}

  // Customised homepage slider banner slices starts here

  const homePageSliderBannerSlices = data?.slices?.filter(
    (slice: any) => slice.slice_type === 'home_page_slider_banner'
  )

  const otherSlices = data?.slices?.filter(
    (slice: any) => slice.slice_type !== 'home_page_slider_banner'
  )

  const selectedSliderBannerSlice =
    homePageSliderBannerSlices.find((slice: any) =>
      customerTags.includes(slice?.primary?.customerGroup?.toLowerCase())
    ) ||
    homePageSliderBannerSlices.find(
      (slice: any) => slice?.primary?.customerGroup === null
    )

  const selectedSlices = [
    ...(selectedSliderBannerSlice ? [selectedSliderBannerSlice] : []),
    ...otherSlices,
  ]

  // Customised homepage slider banner slices ends here

  return (
    <>
      <SeoHead
        title={seoTitle || ''}
        description={seoDescription || ''}
        image={seoImage}
      />
      <SliceZone
        slices={selectedSlices}
        components={components}
        context={{ collectionProducts }}
      />
    </>
  )
}

Home.Layout = Layout
